import React from "react";
import { Image, Platform, View } from "react-native";
import { logoStyle, logoWebStyle } from "../styles";

function RegioneLiguriaLogo() {
	return (
		<View>
			<Image
				source={require('./RegioneLiguria.png')}
				fadeDuration={0}
				resizeMode="contain"
				style={Platform.OS === "web" ? logoWebStyle : logoStyle}
			/>
		</View>
	)
}

export default RegioneLiguriaLogo;