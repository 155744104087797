import React, { useEffect, useState } from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import { EHobbies } from '../../common/enums';
import { ISchool } from '../../common/interfaces';
import i18 from '../../lib/i18';
import { hobbiesToSchool } from '../../lib/schools';
import { AgrariaIcon, AnimaliIcon, BotanicaIcon, CantoIcon, ChimicaIcon, CinemaIcon, CucinaIcon, EconomiaIcon, EsteticaIcon, FotografiaIcon, GeometriaIcon, LettereIcon, LingueIcon, MatematicaIcon, MeccanicaIcon, MedicinaIcon, ModaIcon, MusicaIcon, NauticaIcon, PitturaIcon, PsicologiaIcon, SculturaIcon, SportIcon, StoriaIcon, StrumentiIcon, TeatroIcon, TecnologiaIcon, TurismoIcon } from '../../ui/icons/hobbies';
import Caption from '../../ui/text/Caption';
import Body from '../text/Body';

interface SelectionIcon {
	hobby: EHobbies,
	selected: boolean,
}

interface SelectionCaption {
	hobby: EHobbies
}

interface SelectionProps {
	hobby: EHobbies;
	isSelected: boolean;
	onChangeSelectedHobby: (school: EHobbies) => void;
}


function SelectionIcon({ hobby, selected }: SelectionIcon) {
	if (hobby === EHobbies.medicina) return (<MedicinaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.fotografia) return (<FotografiaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.canto) return (<CantoIcon selected={selected} size="big" />)
	if (hobby === EHobbies.matematica) return (<MatematicaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.economia) return (<EconomiaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.meccanica) return (<MeccanicaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.teatro) return (<TeatroIcon selected={selected} size="big" />)
	if (hobby === EHobbies.sport) return (<SportIcon selected={selected} size="big" />)
	if (hobby === EHobbies.nautica) return (<NauticaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.psicologia) return (<PsicologiaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.moda) return (<ModaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.botanica) return (<BotanicaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.musica) return (<MusicaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.lingue) return (<LingueIcon selected={selected} size="big" />)
	if (hobby === EHobbies.lettere) return (<LettereIcon selected={selected} size="big" />)
	if (hobby === EHobbies.scultura) return (<SculturaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.turismo) return (<TurismoIcon selected={selected} size="big" />)
	if (hobby === EHobbies.cucina) return (<CucinaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.estetica) return (<EsteticaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.storia) return (<StoriaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.animali) return (<AnimaliIcon selected={selected} size="big" />)
	if (hobby === EHobbies.strumenti) return (<StrumentiIcon selected={selected} size="big" />)
	if (hobby === EHobbies.chimica) return (<ChimicaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.pittura) return (<PitturaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.agraria) return (<AgrariaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.cinema) return (<CinemaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.tecnologia) return (<TecnologiaIcon selected={selected} size="big" />)
	if (hobby === EHobbies.geometria) return (<GeometriaIcon selected={selected} size="big" />)
	return null;
}

function SelectionCaption({ hobby }: SelectionCaption) {
	if (hobby === EHobbies.medicina) return (<Caption>{i18.t("medicine")}</Caption>)
	if (hobby === EHobbies.fotografia) return (<Caption>{i18.t("photography")}</Caption>)
	if (hobby === EHobbies.canto) return (<Caption>{i18.t("singing")}</Caption>)
	if (hobby === EHobbies.matematica) return (<Caption>{i18.t("mathematics")}</Caption>)
	if (hobby === EHobbies.economia) return (<Caption>{i18.t("economy")}</Caption>)
	if (hobby === EHobbies.meccanica) return (<Caption>{i18.t("mechanics")}</Caption>)
	if (hobby === EHobbies.teatro) return (<Caption>{i18.t("theater")}</Caption>)
	if (hobby === EHobbies.sport) return (<Caption>{i18.t("sport")}</Caption>)
	if (hobby === EHobbies.nautica) return (<Caption>{i18.t("boating")}</Caption>)
	if (hobby === EHobbies.psicologia) return (<Caption>{i18.t("psychology")}</Caption>)
	if (hobby === EHobbies.moda) return (<Caption>{i18.t("fashion")}</Caption>)
	if (hobby === EHobbies.botanica) return (<Caption>{i18.t("botany")}</Caption>)
	if (hobby === EHobbies.musica) return (<Caption>{i18.t("music")}</Caption>)
	if (hobby === EHobbies.lingue) return (<Caption>{i18.t("languages")}</Caption>)
	if (hobby === EHobbies.lettere) return (<Caption>{i18.t("letters")}</Caption>)
	if (hobby === EHobbies.scultura) return (<Caption>{i18.t("sculpture")}</Caption>)
	if (hobby === EHobbies.turismo) return (<Caption>{i18.t("tourism")}</Caption>)
	if (hobby === EHobbies.cucina) return (<Caption>{i18.t("cooking")}</Caption>)
	if (hobby === EHobbies.estetica) return (<Caption>{i18.t("beauty")}</Caption>)
	if (hobby === EHobbies.storia) return (<Caption>{i18.t("history")}</Caption>)
	if (hobby === EHobbies.animali) return (<Caption>{i18.t("animals")}</Caption>)
	if (hobby === EHobbies.strumenti) return (<Caption>{i18.t("instruments")}</Caption>)
	if (hobby === EHobbies.chimica) return (<Caption>{i18.t("chemistry")}</Caption>)
	if (hobby === EHobbies.pittura) return (<Caption>{i18.t("painting")}</Caption>)
	if (hobby === EHobbies.agraria) return (<Caption>{i18.t("agricultur")}</Caption>)
	if (hobby === EHobbies.cinema) return (<Caption>{i18.t("cinema")}</Caption>)
	if (hobby === EHobbies.tecnologia) return (<Caption>{i18.t("technology")}</Caption>)
	if (hobby === EHobbies.geometria) return (<Caption>{i18.t("geometry")}</Caption>)
	return null;
}



function Selection({ hobby, isSelected, onChangeSelectedHobby }: SelectionProps) {
	const [schools, setSchools] = useState<ISchool[]>([]);
	useEffect(() => {
		setSchools(hobbiesToSchool[hobby]);
	}, [hobby])
	return (
		<View style={containerStyle}>
			<TouchableOpacity style={buttonStyle} onPress={() => onChangeSelectedHobby(hobby)}>
				<SelectionIcon hobby={hobby} selected={isSelected} />
				<SelectionCaption hobby={hobby} />
				<View style={schoolsListStyle}>
					{schools.map((s, index) => (
						<View key={index} style={schoolItemStyle}>
							<Body style={{ textAlign: 'center' }}>{s.name}</Body>
						</View>
					))}
				</View>
			</TouchableOpacity>
		</View>
	);
}

const containerStyle: StyleProp<ViewStyle> = {
	alignItems: "center",
	justifyContent: "flex-start",
	paddingTop: 100,
	flex: 1
}

const schoolItemStyle: StyleProp<ViewStyle> = {
	paddingBottom: 8,
	alignItems: "center",
}

const buttonStyle: StyleProp<ViewStyle> = {
	padding: 15,
	alignItems: "center"
}

const schoolsListStyle: StyleProp<ViewStyle> = {
	paddingTop: 48,
}

export default Selection;
