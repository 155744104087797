/** English - en */
export default {
	"choose your language": "choose your language",
	"choose three of your hobbies": "choose three of your hobbies",
	"choose one of the recommended options": "choose one of the recommended options",
	"link to the website": "link to the website",
	"link to google maps": "link to google maps",
	"medicine": "medicine",
	"photography": "photography",
	"singing": "singing",
	"mathematics": "mathematics",
	"economy": "economy",
	"mechanics": "mechanics",
	"theater": "theater",
	"sport": "sport",
	"boating": "boating",
	"psychology": "psychology",
	"fashion": "fashion",
	"botany": "botany",
	"music": "music",
	"languages": "languages",
	"letters": "letters",
	"sculpture": "sculpture",
	"tourism": "tourism",
	"cooking": "cooking",
	"beauty": "beauty",
	"history": "history",
	"animals": "animals",
	"instruments": "instruments",
	"chemistry": "chemistry",
	"painting": "painting",
	"agricultur": "agricultur",
	"cinema": "cinema",
	"technology": "technology",
	"geometry": "geometry"
};