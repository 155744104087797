/** French - fr */
export default {
	"choose your language": "choisissez votre langue",
	"choose three of your hobbies": "choisissez trois de vos loisirs",
	"choose one of the recommended options": "choisissez l'une des options recommandees",
	"link to the website": "lien vers le site",
	"link to google maps": "lien vers google maps",
	"medicine": "medicament",
	"photography": "photographie",
	"singing": "chant",
	"mathematics": "mathematiques",
	"economy": "economie",
	"mechanics": "mecanique",
	"theater": "theatre",
	"sport": "sport",
	"boating": "nautique",
	"psychology": "psychologie",
	"fashion": "mode",
	"botany": "botanique",
	"music": "musique",
	"languages": "langues",
	"letters": "lettres",
	"sculpture": "sculpture",
	"tourism": "tourisme",
	"cooking": "cuisine",
	"beauty": "esthetique",
	"history": "histoire",
	"animals": "animaux",
	"instruments": "instruments",
	"chemistry": "chimie",
	"painting": "peinture",
	"agricultur": "agraire",
	"cinema": "cinema",
	"technology": "technologie",
	"geometry": "geometrie"
};