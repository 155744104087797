import React from 'react';
import { StyleProp, Text, TextProps, TextStyle } from 'react-native';

function Body({ children, style }: TextProps) {
	const mergedStyle = { ...style as object, ...bodyStyle as object }
	return (
		<Text style={mergedStyle}>{children}</Text>
	)
}
const bodyStyle: StyleProp<TextStyle> = {
	fontFamily: "Gontserrat",
	fontSize: 16,
}


export default Body;