import React from 'react';
import { StyleProp, Text, TextProps, TextStyle } from 'react-native';

function Caption({ children }: TextProps) {
	return (
		<Text style={captionStyle}>{children}</Text>
	)
}
const captionStyle: StyleProp<TextStyle> = {
	fontFamily: "Gontserrat",
	fontSize: 14
}


export default Caption;