import React from 'react';
import { Platform, StyleProp, Text, TextStyle, View, ViewStyle } from 'react-native';
import MinisteroInternoLogo from '../logos/MinisteroInternoLogo';
import MinisteroLavoroLogo from '../logos/MinisteroLavoroLogo';
import RegioneLiguriaLogo from '../logos/RegioneLiguriaLogo';
import UnioneEuropeaLogo from '../logos/UnioneEuropeaLogo';


function BottomLogobar() {
	return (
		<View style={logobarStyle}>
			<View style={Platform.OS === "web" ? containerWebStyle : containerStyle}>
				<View style={{ flex: 1, flexDirection: "row" }}>
					<View style={{ flex: 1 }}>
						<UnioneEuropeaLogo />
					</View>
					<View style={{ flex: 1 }}>
						<RegioneLiguriaLogo />
					</View >
				</View>

				<View style={{ flex: 1 }}>
					<View style={{ marginBottom: 8 }}>
						<Text style={mainTextStyle}>FONDO ASILO, MIGRAZIONE E INTEGRAZIONE</Text>
						<Text style={mainTextStyle}>(FAMI) 2014-2020</Text>
					</View>
					<Text style={textStyle}>
						OS2 Integrazione/Migrazione legale - ON2 Integrazione - Piani d’intervento regionali per l’integrazione dei cittadini di paesi terzi - Autorità Delegata - IMPACT
					</Text>
				</View>
				<View style={{ flex: 1, flexDirection: "row" }}>
					<View style={{ flex: 1 }}>
						<MinisteroLavoroLogo />
					</View>
					<View style={{ flex: 1 }}>
						<MinisteroInternoLogo />
					</View>
				</View>
			</View>
		</View>
	)
}

const logobarStyle: StyleProp<ViewStyle> = {
	padding: 80,
	justifyContent: "center",
	flexDirection: "row"
}
const containerStyle: StyleProp<ViewStyle> = {
	flexDirection: "row",
	width: "100%",
	paddingTop: 10,
	paddingHorizontal: 20
}
const containerWebStyle: StyleProp<ViewStyle> = {
	flexDirection: "row",
	width: "100%",
	maxWidth: 1360,
	paddingTop: 10,
	paddingHorizontal: 20
}

const mainTextStyle: StyleProp<TextStyle> = {
	fontFamily: "Gontserrat",
	textAlign: 'center',
	fontSize: 14
}

const textStyle: StyleProp<TextStyle> = {
	fontFamily: "Gontserrat",
	textAlign: 'center',
	fontSize: 12
}

export default BottomLogobar;