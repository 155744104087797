import React from 'react';
import { StyleProp, Text, TextProps, TextStyle } from 'react-native';

function Title({ children }: TextProps) {
	return (
		<Text style={titleStyle}>{children}</Text>
	)
}
const titleStyle: StyleProp<TextStyle> = {
	fontFamily: "GontserratBold",
	fontWeight: "bold",
	textAlign: "center",
	padding: 20,
	fontSize: 20
}


export default Title;