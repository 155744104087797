import React from 'react';
import { StyleProp, Text, TextProps, TextStyle } from 'react-native';

function Underlined({ children, style }: TextProps) {
	const mergedStyle = { ...bodyStyle as object, ...style as object }
	return (
		<Text style={mergedStyle}>{children}</Text>
	)
}
const bodyStyle: StyleProp<TextStyle> = {
	fontFamily: "Gontserrat",
	textDecorationLine: 'underline',
	fontSize: 16
}


export default Underlined;