import React from 'react';
import { Platform, ScrollView, StyleProp, View, ViewStyle } from 'react-native';
import { EHobbies } from '../../common/enums';
import i18 from '../../lib/i18';
import Title from '../../ui/text/Title';
import Selection from '../../ui/Selection'
import IconButton from '../../ui/button/IconButton';
import NextIcon from '../../ui/icons/NextIcon';
import PrevIcon from '../../ui/icons/PrevIcon';

interface ChoiceProps {
	selectedHobbies: EHobbies[];
	selectedHobby: EHobbies | null;
	onChangeSelectedHobby: (hobby: EHobbies) => void;
	onPrev: () => void;
	onNext: () => void;
}

function ChoiceScreen({ selectedHobbies, selectedHobby, onChangeSelectedHobby, onPrev, onNext }: ChoiceProps) {
	return (
		<View style={containerStyle}>
			<Title>{i18.t("choose one of the recommended options")}</Title>
			<ScrollView
				style={Platform.OS === "web" ? choiceScroolWebStyle : choiceScroolStyle}
				contentContainerStyle={Platform.OS === "web" ? choiceContainerWebStyle : choiceContainerStyle}
			>
				{selectedHobbies.map((hobby, index) => (
					<Selection key={index} hobby={hobby} isSelected={hobby === selectedHobby} onChangeSelectedHobby={onChangeSelectedHobby} />
				))}
			</ScrollView>
			<View style={Platform.OS === "web" ? nextWebStyle : nextStyle}>
				<View >
					<IconButton Icon={PrevIcon} onPress={onPrev}></IconButton>
				</View>
				{!!selectedHobby &&
					<View>
						<IconButton Icon={NextIcon} onPress={onNext}></IconButton>
					</View>
				}
			</View>
		</View >
	);
}

const containerStyle: StyleProp<ViewStyle> = {
	flex: 1,
	paddingTop: 0,
	alignItems: "center",
}

const choiceScroolStyle: StyleProp<ViewStyle> = {

}
const choiceScroolWebStyle: StyleProp<ViewStyle> = {
	width: "100%",
	maxWidth: 1360
}
const choiceContainerStyle: StyleProp<ViewStyle> = {
	justifyContent: "center",
}

const choiceContainerWebStyle: StyleProp<ViewStyle> = {
	justifyContent: "space-around",
	flexDirection: "row",
	width: "100%",
	maxWidth: 1360,
	height: "100%"
}

const nextStyle: StyleProp<ViewStyle> = {
	width: "100%",
	height: 80,
	flexDirection: "row",
	justifyContent: "space-between",
	paddingHorizontal: 20,
	paddingBottom: 20
}

const nextWebStyle: StyleProp<ViewStyle> = {
	width: "100%",
	maxWidth: 1360,
	height: 80,
	flexDirection: "row",
	justifyContent: "space-between",
	paddingHorizontal: 20,
	paddingBottom: 20
}

export default ChoiceScreen;