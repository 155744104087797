/** Bengali - bn */
export default {
	"choose your language": "আপনার ভাষা পছন্দ করুন",
	"choose three of your hobbies": "আপনার তিনটি শখ বেছে নিন",
	"choose one of the recommended options": "প্রস্তাবিত বিকল্পগুলির মধ্যে একটি বেছে নিন",
	"link to the website": "ওয়েবসাইটের লিঙ্ক",
	"link to google maps": "গুগল ম্যাপের লিঙ্ক",
	"medicine": "ওষুধ",
	"photography": "ফটোগ্রাফি",
	"singing": "গান",
	"mathematics": "গণিত",
	"economy": "অর্থনীতি",
	"mechanics": "মেকানিক্স",
	"theater": "থিয়েটার",
	"sport": "খেলা",
	"boating": "নটিক্যাল (সমুদ্র)",
	"psychology": "মনোবিজ্ঞান",
	"fashion": "ফ্যাশন",
	"botany": "উদ্ভিদবিদ্যা",
	"music": "সঙ্গীত",
	"languages": "ভাষা",
	"letters": "চিঠিপত্র",
	"sculpture": "মূর্তি",
	"tourism": "ট্যুরিজম",
	"cooking": "রান্নাঘর",
	"beauty": "নান্দনিকতা",
	"history": "ইতিহাস",
	"animals": "পশু-প্রাণী",
	"instruments": "যন্ত্র",
	"chemistry": "রসায়ন",
	"painting": "পেইন্টিং",
	"agricultur": "কৃষিজীবী",
	"cinema": "চলচ্চিত্র",
	"technology": "প্রযুক্তি",
	"geometry": "জ্যামিতি"
};