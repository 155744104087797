import { useFonts } from 'expo-font';
import React, { useEffect, useState } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import { EHobbies, ELanguages, EPages } from './common/enums';
import HobbiesScreen from './screens/Hobbies';
import LanguageScreen from './screens/Languages';
import Navbar from './ui/Navbar';
import i18, { changeLocale, initLocale } from "./lib/i18";
import ChoiceScreen from './screens/Choice';
import SchoolScreen from './screens/School';
import Logobar from './ui/Logobar';
import { ISchool } from './common/interfaces';
import { hobbiesToSchool } from './lib/schools';
import MainScreen from './screens/Main';
function App() {
	const [page, setPage] = useState<EPages>(EPages.main);
	const [lang, setLang] = useState<ELanguages | null>(null);
	const [selectedHobbies, setSelectedHobbies] = useState<EHobbies[]>([]);
	const [selectedHobby, setSelectedHobby] = useState<EHobbies | null>(null);
	const [schools, setSchools] = useState<ISchool[]>([]);

	const onChangeLang = (selectedLang: ELanguages) => {
		console.log(selectedLang);
		if (selectedLang) changeLocale(selectedLang);
		if (selectedLang === lang) setLang(null);
		else setLang(selectedLang);
	}

	const onChangeSelectedHobbies = (selected: EHobbies) => {
		console.log(selected)
		if (selectedHobbies.includes(selected)) {
			setSelectedHobbies(selectedHobbies.filter(i => i !== selected));
		} else if (selectedHobbies.length < 3) {
			setSelectedHobbies([...selectedHobbies, selected]);
		}
	}

	const onChangeSelectedHobby = (selected: EHobbies) => {
		if (selectedHobby === selected) {
			setSelectedHobby(null);
			setSchools([]);
		}
		else {
			setSelectedHobby(selected);
			setSchools(hobbiesToSchool[selected]);
		}
	}

	const onGoToHome = () => {
		setPage(EPages.main);
	}
	const onGoToLangSelect = () => setPage(EPages.languages);

	const [loaded] = useFonts({
		Gontserrat: require('./assets/fonts/Gontserrat-Regular.ttf'),
		GontserratBold: require('./assets/fonts/Gontserrat-Bold.ttf'),
	});
	useEffect(() => {
		const lang: ELanguages = initLocale();
		setLang(lang)
	}, [])
	useEffect(() => {
		if (lang) changeLocale(lang);
	}, [lang])
	if (!loaded) {
		return null;
	}
	return (
		<View style={containerStyle}>
			{page !== EPages.main && <Navbar lang={lang} onGoToHome={onGoToHome} onGoToLangSelect={onGoToLangSelect} />}
			{page !== EPages.main && <Logobar />}
			{page === EPages.main && <MainScreen onNext={() => { console.log('next'); setPage(EPages.languages) }} />}
			{page === EPages.languages && <LanguageScreen lang={lang} onChangeLang={onChangeLang} onNext={() => setPage(EPages.hobbies)} />}
			{page === EPages.hobbies && <HobbiesScreen selectedHobbies={selectedHobbies} onChangeSelectedHobbies={onChangeSelectedHobbies} onNext={() => setPage(EPages.choice)} />}
			{page === EPages.choice && <ChoiceScreen selectedHobbies={selectedHobbies} selectedHobby={selectedHobby} onChangeSelectedHobby={onChangeSelectedHobby} onPrev={() => setPage(EPages.hobbies)} onNext={() => setPage(EPages.school)} />}
			{page === EPages.school && <SchoolScreen schools={schools} onPrev={() => setPage(EPages.choice)} />}
		</View>
	);
}

const containerStyle: StyleProp<ViewStyle> = {
	flex: 1,
}

export default App;