import React from 'react';
import { Platform, ScrollView, StyleProp, View, ViewStyle } from 'react-native';
import { EHobbies } from '../../common/enums';
import i18 from '../../lib/i18';
import IconButton from '../../ui/button/IconButton';
import NextIcon from '../../ui/icons/NextIcon';
import { AgrariaIcon, AnimaliIcon, BotanicaIcon, CantoIcon, ChimicaIcon, CinemaIcon, CucinaIcon, EconomiaIcon, EsteticaIcon, FotografiaIcon, GeometriaIcon, LettereIcon, LingueIcon, MatematicaIcon, MeccanicaIcon, MedicinaIcon, ModaIcon, MusicaIcon, NauticaIcon, PitturaIcon, PsicologiaIcon, SculturaIcon, SportIcon, StoriaIcon, StrumentiIcon, TeatroIcon, TecnologiaIcon, TurismoIcon } from '../../ui/icons/hobbies';
import Caption from '../../ui/text/Caption';
import Title from '../../ui/text/Title';

interface HobbiesScreenProps {
	selectedHobbies: EHobbies[];
	onChangeSelectedHobbies: (hobby: EHobbies) => void;
	onNext: () => void
}

function HobbiesScreen({ selectedHobbies, onChangeSelectedHobbies, onNext }: HobbiesScreenProps) {

	return (
		<View style={containerStyle}>
			<Title>{i18.t("choose three of your hobbies")}</Title>
			<ScrollView contentContainerStyle={Platform.OS === "web" ? hobbiesContainerWebStyle : hobbiesContainerStyle} >
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.medicina)} Icon={MedicinaIcon} selected={selectedHobbies.includes(EHobbies.medicina)}></IconButton>
					<Caption>{i18.t("medicine")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.fotografia)} Icon={FotografiaIcon} selected={selectedHobbies.includes(EHobbies.fotografia)}></IconButton>
					<Caption>{i18.t("photography")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.canto)} Icon={CantoIcon} selected={selectedHobbies.includes(EHobbies.canto)}></IconButton>
					<Caption>{i18.t("singing")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.matematica)} Icon={MatematicaIcon} selected={selectedHobbies.includes(EHobbies.matematica)}></IconButton>
					<Caption>{i18.t("mathematics")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.economia)} Icon={EconomiaIcon} selected={selectedHobbies.includes(EHobbies.economia)}></IconButton>
					<Caption>{i18.t("economy")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.meccanica)} Icon={MeccanicaIcon} selected={selectedHobbies.includes(EHobbies.meccanica)}></IconButton>
					<Caption>{i18.t("mechanics")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.teatro)} Icon={TeatroIcon} selected={selectedHobbies.includes(EHobbies.teatro)}></IconButton>
					<Caption>{i18.t("theater")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.sport)} Icon={SportIcon} selected={selectedHobbies.includes(EHobbies.sport)}></IconButton>
					<Caption>{i18.t("sport")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.nautica)} Icon={NauticaIcon} selected={selectedHobbies.includes(EHobbies.nautica)}></IconButton>
					<Caption>{i18.t("boating")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.psicologia)} Icon={PsicologiaIcon} selected={selectedHobbies.includes(EHobbies.psicologia)}></IconButton>
					<Caption>{i18.t("psychology")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.moda)} Icon={ModaIcon} selected={selectedHobbies.includes(EHobbies.moda)}></IconButton>
					<Caption>{i18.t("fashion")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.botanica)} Icon={BotanicaIcon} selected={selectedHobbies.includes(EHobbies.botanica)}></IconButton>
					<Caption>{i18.t("botany")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.musica)} Icon={MusicaIcon} selected={selectedHobbies.includes(EHobbies.musica)}></IconButton>
					<Caption>{i18.t("music")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.lingue)} Icon={LingueIcon} selected={selectedHobbies.includes(EHobbies.lingue)}></IconButton>
					<Caption>{i18.t("languages")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.lettere)} Icon={LettereIcon} selected={selectedHobbies.includes(EHobbies.lettere)}></IconButton>
					<Caption>{i18.t("letters")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.scultura)} Icon={SculturaIcon} selected={selectedHobbies.includes(EHobbies.scultura)}></IconButton>
					<Caption>{i18.t("sculpture")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.turismo)} Icon={TurismoIcon} selected={selectedHobbies.includes(EHobbies.turismo)}></IconButton>
					<Caption>{i18.t("tourism")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.cucina)} Icon={CucinaIcon} selected={selectedHobbies.includes(EHobbies.cucina)}></IconButton>
					<Caption>{i18.t("cooking")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.estetica)} Icon={EsteticaIcon} selected={selectedHobbies.includes(EHobbies.estetica)}></IconButton>
					<Caption>{i18.t("beauty")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.storia)} Icon={StoriaIcon} selected={selectedHobbies.includes(EHobbies.storia)}></IconButton>
					<Caption>{i18.t("history")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.animali)} Icon={AnimaliIcon} selected={selectedHobbies.includes(EHobbies.animali)}></IconButton>
					<Caption>{i18.t("animals")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.strumenti)} Icon={StrumentiIcon} selected={selectedHobbies.includes(EHobbies.strumenti)}></IconButton>
					<Caption>{i18.t("instruments")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.chimica)} Icon={ChimicaIcon} selected={selectedHobbies.includes(EHobbies.chimica)}></IconButton>
					<Caption>{i18.t("chemistry")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.pittura)} Icon={PitturaIcon} selected={selectedHobbies.includes(EHobbies.pittura)}></IconButton>
					<Caption>{i18.t("painting")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.agraria)} Icon={AgrariaIcon} selected={selectedHobbies.includes(EHobbies.agraria)}></IconButton>
					<Caption>{i18.t("agricultur")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.cinema)} Icon={CinemaIcon} selected={selectedHobbies.includes(EHobbies.cinema)}></IconButton>
					<Caption>{i18.t("cinema")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.tecnologia)} Icon={TecnologiaIcon} selected={selectedHobbies.includes(EHobbies.tecnologia)}></IconButton>
					<Caption>{i18.t("technology")}</Caption>
				</View>
				<View style={buttonStyle}>
					<IconButton onPress={() => onChangeSelectedHobbies(EHobbies.geometria)} Icon={GeometriaIcon} selected={selectedHobbies.includes(EHobbies.geometria)}></IconButton>
					<Caption>{i18.t("geometry")}</Caption>
				</View>
			</ScrollView >
			<View style={Platform.OS === "web" ? nextWebStyle : nextStyle}>
				<View />
				{selectedHobbies.length === 3 &&
					<View>
						<IconButton Icon={NextIcon} onPress={onNext}></IconButton>
					</View>
				}
			</View>
		</View>
	);
}

const containerStyle: StyleProp<ViewStyle> = {
	flex: 1,
	alignItems: "center",
}

const hobbiesContainerStyle: StyleProp<ViewStyle> = {
	flexDirection: "row",
	justifyContent: "center",
	flexWrap: "wrap",
}

const hobbiesContainerWebStyle: StyleProp<ViewStyle> = {
	flexDirection: "row",
	justifyContent: "center",
	maxWidth: 1360,
	flexWrap: "wrap",
}

const buttonStyle: StyleProp<ViewStyle> = {
	padding: 15,
	alignItems: "center"
}

const nextStyle: StyleProp<ViewStyle> = {
	width: "100%",
	height: 80,
	flexDirection: "row",
	justifyContent: "space-between",
	paddingHorizontal: 20,
	paddingBottom: 20
}

const nextWebStyle: StyleProp<ViewStyle> = {
	width: "100%",
	maxWidth: 1360,
	height: 80,
	flexDirection: "row",
	justifyContent: "space-between",
	paddingHorizontal: 20,
	paddingBottom: 20
}

export default HobbiesScreen;
