import React from 'react';
import { Platform, StyleProp, Text, TextStyle, TouchableOpacity, TouchableWithoutFeedback, View, ViewStyle } from 'react-native';
import { ELanguages } from '../../common/enums';
import IconButton from '../button/IconButton';
import { FranceseIcon, IngleseIcon, ItalianoIcon } from '../icons/languages';
import AlbaneseIcon from '../icons/languages/AlbaneseIcon';
import AraboIcon from '../icons/languages/AraboIcon';
import BanglaIcon from '../icons/languages/BanglaIcon';
import CineseIcon from '../icons/languages/CineseIcon';
import SpagnoloIcon from '../icons/languages/SpagnoloIcon';
import LogoIcon from '../icons/LogoIcon';

interface NavbarProps {
	lang?: ELanguages | null;
	onGoToHome: () => void;
	onGoToLangSelect: () => void,
}

function Navbar({ lang, onGoToHome, onGoToLangSelect }: NavbarProps) {
	return (
		<View style={navbarStyle}>
			<View style={Platform.OS === "web" ? containerWebStyle : containerStyle}>
				<TouchableOpacity onPress={onGoToHome}>
					<View style={logoStyle}>
						<LogoIcon />
						<Text style={titleStyle}> app(L)y</Text>
					</View>
				</TouchableOpacity>
				<View>
					{lang === ELanguages.en && <IconButton Icon={IngleseIcon} size="small" onPress={onGoToLangSelect} />}
					{lang === ELanguages.es && <IconButton Icon={SpagnoloIcon} size="small" onPress={onGoToLangSelect} />}
					{lang === ELanguages.fr && <IconButton Icon={FranceseIcon} size="small" onPress={onGoToLangSelect} />}
					{lang === ELanguages.it && <IconButton Icon={ItalianoIcon} size="small" onPress={onGoToLangSelect} />}
					{lang === ELanguages.ar && <IconButton Icon={AraboIcon} size="small" onPress={onGoToLangSelect} />}
					{lang === ELanguages.bn && <IconButton Icon={BanglaIcon} size="small" onPress={onGoToLangSelect} />}
					{lang === ELanguages.sq && <IconButton Icon={AlbaneseIcon} size="small" onPress={onGoToLangSelect} />}
					{lang === ELanguages.zh && <IconButton Icon={CineseIcon} size="small" onPress={onGoToLangSelect} />}
				</View>
			</View>
		</View>
	)
}

const navbarStyle: StyleProp<ViewStyle> = {
	justifyContent: "center",
	flexDirection: "row",
	backgroundColor: "#add8f5",
}
const containerStyle: StyleProp<ViewStyle> = {
	height: 120,
	width: "100%",
	justifyContent: "space-between",
	alignItems: "center",
	paddingTop: 60,
	padding: 20,
	flexDirection: "row"
}
const containerWebStyle: StyleProp<ViewStyle> = {
	height: 80,
	width: "100%",
	maxWidth: 1360,
	backgroundColor: "#add8f5",
	justifyContent: "space-between",
	alignItems: "center",
	padding: 20,
	flexDirection: "row"
}
const logoStyle: StyleProp<ViewStyle> = {
	display: "flex",
	flexDirection: "row",
	alignItems: "center"
}
const titleStyle: StyleProp<TextStyle> = {
	fontFamily: "Gontserrat",
	fontSize: 20
}

export default Navbar;