import { ImageStyle, StyleProp } from "react-native";

export const iconRegular: StyleProp<ImageStyle> = {
	width: 80,
	height: 80
}

export const iconSmall: StyleProp<ImageStyle> = {
	width: 60,
	height: 60
}

export const iconBig: StyleProp<ImageStyle> = {
	width: 100,
	height: 100
}

export const iconExtraBig: StyleProp<ImageStyle> = {
	width: 180,
	height: 180
}

export const iconSelected: StyleProp<ImageStyle> = {
	width: 35,
	height: 50,
	position: "absolute",
	bottom: -5,
	right: -15
}