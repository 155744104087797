import React from "react";
import { TouchableOpacity, TouchableOpacityProps } from "react-native";
import { IconProps } from '../../icons/props';

interface IconButtonProps extends TouchableOpacityProps {
	Icon: (props: IconProps) => JSX.Element;
	selected?: boolean;
	size?: "regular" | "small" | "big";
}

function IconButton({ onPress, Icon, selected, size }: IconButtonProps) {
	return (
		<TouchableOpacity onPress={onPress}>
			<Icon selected={selected} size={size} />
		</TouchableOpacity>
	)
}

export default IconButton;