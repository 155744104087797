/** Arabic - ar */
export default {
	"choose your language": "اختر لغتك",
	"choose three of your hobbies": "اختر ثلاثة من هواياتك",
	"choose one of the recommended options": "اختر أحد الخيرات الموجودة",
	"link to the website": "واصل الرابط بالموقع الشبك",
	"link to google maps": "واصل موقع جوجل",
	"medicine": "طب",
	"photography": "تصوير",
	"singing": "غناء",
	"mathematics": "رياضيات",
	"economy": "اقتصاد",
	"mechanics": "ميكانيكية",
	"theater": "مسرح",
	"sport": "رياضة",
	"boating": "بحري",
	"psychology": "علم النفس",
	"fashion": "الازياء",
	"botany": "علم النباتات",
	"music": "موسيقى",
	"languages": "لغات",
	"letters": "الشعر",
	"sculpture": "نحت",
	"tourism": "سياحة ",
	"cooking": "طبخ",
	"beauty": "تجميل",
	"history": "تاريخ",
	"animals": "حيوانات",
	"instruments": "معدات",
	"chemistry": "كيميائية",
	"painting": "صباغة",
	"agricultur": "الزراعة",
	"cinema": "سينما",
	"technology": "تكنولوجيا",
	"geometry": "هندسة"
};