/** Spanish - es */
export default {
	"choose your language": "elige tu idioma",
	"choose three of your hobbies": "elige tres de tus pasatiempos",
	"choose one of the recommended options": "elige una de las opciones recomendadas",
	"link to the website": "conexion a sto web",
	"link to google maps": "conexion a google maps",
	"medicine": "medicina",
	"photography": "fotografia",
	"singing": "canto",
	"mathematics": "matematicas",
	"economy": "economia",
	"mechanics": "mecanica",
	"theater": "teatro",
	"sport": "deporte",
	"boating": "nautico",
	"psychology": "psicologia",
	"fashion": "moda",
	"botany": "botanica",
	"music": "musica",
	"languages": "idiomas",
	"letters": "letras",
	"sculpture": "escultura",
	"tourism": "turismo",
	"cooking": "cocina",
	"beauty": "estetica",
	"history": "historia",
	"animals": "animales",
	"instruments": "istrumentos",
	"chemistry": "quimica",
	"painting": "pintura",
	"agricultur": "agrario",
	"cinema": "cine",
	"technology": "tecnologia",
	"geometry": "geometria"
};