/** Italian - it */
export default {
	"choose your language": "scegli la tua lingua",
	"choose three of your hobbies": "scegli tre dei tuoi hobby",
	"choose one of the recommended options": "scegli una delle opzioni consigliate",
	"link to the website": "collegamento al sito web",
	"link to google maps": "collegamento a google maps",
	"medicine": "medicina",
	"photography": "fotografia",
	"singing": "canto",
	"mathematics": "matematica",
	"economy": "economia",
	"mechanics": "meccanica",
	"theater": "teatro",
	"sport": "sport",
	"boating": "nautica",
	"psychology": "psicologia",
	"fashion": "moda",
	"botany": "botanica",
	"music": "musica",
	"languages": "lingue",
	"letters": "lettere",
	"sculpture": "scultura",
	"tourism": "turismo",
	"cooking": "cucina",
	"beauty": "estetica",
	"history": "storia",
	"animals": "animali",
	"instruments": "strumenti",
	"chemistry": "chimica",
	"painting": "pittura",
	"agricultur": "agraria",
	"cinema": "cinema",
	"technology": "tecnologia",
	"geometry": "geometria"
};