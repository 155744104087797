import ar from './ar';
import bn from './bn';
import en from './en';
import es from './es';
import fr from './fr';
import it from './it';
import sq from './sq';
import zh from './zh';

const translations = {
	ar,
	bn,
	en,
	es,
	fr,
	it,
	sq,
	zh
};

export default translations;