import React from "react";
import { Image, View } from "react-native";
import { IconProps } from "../../props";
import { iconRegular, iconSelected, iconSmall } from "../../styles";

function CineseIcon({ selected, size }: IconProps) {
	return (
		<View>
			<Image
				source={require('./cina.png')}
				fadeDuration={0}
				resizeMode="contain"
				style={(size === "small") ? iconSmall : iconRegular}
			/>

			{selected &&
				<Image
					source={require('../../extra/Selected.png')}
					fadeDuration={0}
					resizeMode="contain"
					style={iconSelected}
				/>
			}
		</View>
	)
}

export default CineseIcon;