import React from 'react';
import { Platform, ScrollView, StyleProp, View, ViewStyle } from 'react-native';
import { ELanguages } from '../../common/enums';
import i18 from '../../lib/i18';
import IconButton from '../../ui/button/IconButton';
import NextIcon from '../../ui/icons/NextIcon';
import { FranceseIcon, IngleseIcon, ItalianoIcon } from '../../ui/icons/languages';
import SpagnoloIcon from '../../ui/icons/languages/SpagnoloIcon';
import Title from '../../ui/text/Title';
import AlbaneseIcon from '../../ui/icons/languages/AlbaneseIcon';
import CineseIcon from '../../ui/icons/languages/CineseIcon';
import AraboIcon from '../../ui/icons/languages/AraboIcon';
import BanglaIcon from '../../ui/icons/languages/BanglaIcon';

interface LanguagesScreenProps {
	lang: ELanguages | null;
	onChangeLang: (lang: ELanguages) => void;
	onNext: () => void
}

function LanguagesScreen({ lang, onChangeLang, onNext }: LanguagesScreenProps) {

	return (
		<View style={containerStyle}>
			<Title>{i18.t("choose your language")}</Title>
			<ScrollView contentContainerStyle={Platform.OS === "web" ? langContainerWebStyle : langContainerStyle} >
				<View >
					<View style={{ flexDirection: "row" }}>
						<View style={buttonStyle}>
							<IconButton onPress={() => onChangeLang(ELanguages.sq)} Icon={AlbaneseIcon} selected={lang === ELanguages.sq}></IconButton>
						</View>
						<View style={buttonStyle}>
							<IconButton onPress={() => onChangeLang(ELanguages.zh)} Icon={CineseIcon} selected={lang === ELanguages.zh}></IconButton>
						</View>
						<View style={buttonStyle}>
							<IconButton onPress={() => onChangeLang(ELanguages.ar)} Icon={AraboIcon} selected={lang === ELanguages.ar}></IconButton>
						</View>
						<View style={buttonStyle}>
							<IconButton onPress={() => onChangeLang(ELanguages.bn)} Icon={BanglaIcon} selected={lang === ELanguages.bn}></IconButton>
						</View>
					</View>
					<View style={{ flexDirection: "row" }}>
						<View style={buttonStyle}>
							<IconButton onPress={() => onChangeLang(ELanguages.it)} Icon={ItalianoIcon} selected={lang === ELanguages.it}></IconButton>
						</View>
						<View style={buttonStyle}>
							<IconButton onPress={() => onChangeLang(ELanguages.fr)} Icon={FranceseIcon} selected={lang === ELanguages.fr}></IconButton>
						</View>
						<View style={buttonStyle}>
							<IconButton onPress={() => onChangeLang(ELanguages.en)} Icon={IngleseIcon} selected={lang === ELanguages.en}></IconButton>
						</View>
						<View style={buttonStyle}>
							<IconButton onPress={() => onChangeLang(ELanguages.es)} Icon={SpagnoloIcon} selected={lang === ELanguages.es}></IconButton>
						</View>
					</View>
				</View>
			</ScrollView>
			<View style={Platform.OS === "web" ? nextWebStyle : nextStyle}>
				<View />
				{!!lang &&
					<View>
						<IconButton Icon={NextIcon} onPress={onNext}></IconButton>
					</View>
				}
			</View>
		</View>
	);
}

const containerStyle: StyleProp<ViewStyle> = {
	flex: 1,
	paddingTop: 0,
	alignItems: "center",
}

const langContainerStyle: StyleProp<ViewStyle> = {
	flexDirection: "row",
	justifyContent: "center",
	flexWrap: "wrap",
}

const langContainerWebStyle: StyleProp<ViewStyle> = {
	flexDirection: "row",
	justifyContent: "center",
	maxWidth: 1360,
	flexWrap: "wrap",
}

const buttonStyle: StyleProp<ViewStyle> = {
	padding: 15,
	alignItems: "center"
}

const nextStyle: StyleProp<ViewStyle> = {
	width: "100%",
	height: 80,
	flexDirection: "row",
	justifyContent: "space-between",
	paddingHorizontal: 20,
	paddingBottom: 20
}
const nextWebStyle: StyleProp<ViewStyle> = {
	width: "100%",
	maxWidth: 1360,
	height: 80,
	flexDirection: "row",
	justifyContent: "space-between",
	paddingHorizontal: 20,
	paddingBottom: 20
}

export default LanguagesScreen;
