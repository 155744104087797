import * as Localization from 'expo-localization';
import i18n from 'i18n-js';

import translations from "../translations";
import { ELanguages } from '../common/enums';

i18n.defaultLocale = "en";
i18n.fallbacks = true;
i18n.translations = translations;

export const initLocale = (): ELanguages => {
	const locale = Localization.locale;
	return locale.slice(0, 2) as ELanguages;
}
export const changeLocale = (lang: ELanguages) => {
	i18n.locale = lang;
}

export default i18n;