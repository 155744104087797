import { EHobbies } from "../common/enums";
import { ISchool } from "../common/interfaces";

export const hobbiesToSchool: Record<EHobbies, ISchool[]> = {
	[EHobbies.medicina]: [
		{
			name: "Liceo Scientifico A. Pacinotti - indirizzo biomedico",
			mapLink: "https://www.google.it/maps/place/Liceo+Scientifico+Statale+Antonio+Pacinotti/@44.1080014,9.8067531,17z/data=!3m1!4b1!4m5!3m4!1s0x12d4fb66b7febad9:0x2513c742160765f2!8m2!3d44.1080014!4d9.8089418",
			webLink: "https://www.liceopacinotti.edu.it/"
		},
		{
			name: "Parentucelli Arzelà Liceo Scientifico",
			mapLink: "https://www.google.com/maps/place/I.I.S.+Parentucelli-Arzel%C3%A0/@44.1158646,9.9639358,15z/data=!4m2!3m1!1s0x0:0xc758751035450e1?sa=X&ved=2ahUKEwiZ9bmZ3J74AhXR_rsIHeArCjwQ_BJ6BAhAEAU",
			webLink: "https://www.parentucelli-arzela.edu.it/"
		},
		{
			name: "I.I.S.S. L. Einaudi - D. Chiodo - odontotecncico servizio per la sanità e assistenza sociale",
			mapLink: "https://www.google.com/maps/place/Istituto+Einaudi+Chiodo/@44.1111799,9.8115276,15z/data=!4m2!3m1!1s0x0:0x2fbf8d194c7295d4?sa=X&ved=2ahUKEwjH44KB4J74AhUfh_0HHa8-BQQQ_BJ6BAg_EAU",
			webLink: "https://www.einaudichiodo.edu.it/"
		},
	],
	[EHobbies.fotografia]: [
		{
			name: "Cardarelli Liceo Artistico - indirizzo multimediale",
			mapLink: "https://www.google.com/maps/place/Istituto+Superiore+Statale+Vincenzo+Cardarelli/@44.1249056,9.8438311,15z/data=!4m2!3m1!1s0x0:0xd88e1c37c167ca86?sa=X&ved=2ahUKEwid9LX_4J74AhX2hP0HHcUiBpUQ_BJ6BAhREAU",
			webLink: "http://www.istitutocardarelli.edu.it/cardarelli/"
		},
		{
			name: "I.I.S.S. L. Einaudi - D. Chiodo - professionale grafico multimediale",
			mapLink: "https://www.google.com/maps/place/Istituto+Einaudi+Chiodo/@44.1111799,9.8115276,15z/data=!4m2!3m1!1s0x0:0x2fbf8d194c7295d4?sa=X&ved=2ahUKEwjH44KB4J74AhUfh_0HHa8-BQQQ_BJ6BAg_EAU",
			webLink: "https://www.einaudichiodo.edu.it/"
		},
	],
	[EHobbies.canto]: [
		{
			name: "Cardarelli Liceo Musicale",
			mapLink: "https://www.google.com/maps/place/Istituto+Superiore+Statale+Vincenzo+Cardarelli/@44.1249056,9.8438311,15z/data=!4m2!3m1!1s0x0:0xd88e1c37c167ca86?sa=X&ved=2ahUKEwid9LX_4J74AhX2hP0HHcUiBpUQ_BJ6BAhREAU",
			webLink: "http://www.istitutocardarelli.edu.it/cardarelli/"
		}
	],
	[EHobbies.matematica]: [
		{
			name: "Liceo Scientifico A. Pacinotti",
			mapLink: "https://www.google.it/maps/place/Liceo+Scientifico+Statale+Antonio+Pacinotti/@44.1080014,9.8067531,17z/data=!3m1!4b1!4m5!3m4!1s0x12d4fb66b7febad9:0x2513c742160765f2!8m2!3d44.1080014!4d9.8089418",
			webLink: "https://www.liceopacinotti.edu.it/"
		},
		{
			name: "I.S.S. Parentucelli Arzelà Liceo Scientifico",
			mapLink: "https://www.google.com/maps/place/I.I.S.+Parentucelli-Arzel%C3%A0/@44.1158646,9.9639358,15z/data=!4m2!3m1!1s0x0:0xc758751035450e1?sa=X&ved=2ahUKEwiZ9bmZ3J74AhXR_rsIHeArCjwQ_BJ6BAhAEAU",
			webLink: "https://www.parentucelli-arzela.edu.it/"
		},
		{
			name: "CAT Cardarelli",
			mapLink: "https://www.google.com/maps/place/Istituto+di+Istruzione+Superiore+Statale+Cardarelli/@44.113514,9.8470103,15z/data=!4m5!3m4!1s0x0:0xa2127318071dff52!8m2!3d44.113523!4d9.84701",
			webLink: "http://www.istitutocardarelli.edu.it/cardarelli/"
		},
		{
			name: "I.I.S. G. Capellini - N. Sauro Liceo Tecnologico",
			mapLink: "https://www.google.com/maps/place/Istituto+Tecnico+Industriale+Giovanni+Capellini/@44.1115427,9.8364545,15z/data=!4m5!3m4!1s0x0:0xdc2cc6b6a1d43140!8m2!3d44.1117286!4d9.8362014",
			webLink: "https://www.capellinisauro.edu.it/"
		}
	],
	[EHobbies.economia]: [
		{
			name: "Istituto Tecnico Commerciale e Tecnologico A. Fossati - M. Da Passano",
			mapLink: "https://www.google.com/maps/place/Istituto+Tecnico+Commerciale+e+Tecnologico+A.Fossati-M.Da+Passano/@44.116592,9.8488462,15z/data=!4m5!3m4!1s0x0:0xd2d345e515173bf8!8m2!3d44.116592!4d9.8488462",
			webLink: "https://www.fossatidapassano.edu.it/"
		},
		{
			name: "I.S.S. Parentucelli Arzelà Istituto Tecnico",
			mapLink: "https://www.google.com/maps/place/I.I.S.+Parentucelli-Arzel%C3%A0/@44.1158646,9.9639358,15z/data=!4m2!3m1!1s0x0:0xc758751035450e1?sa=X&ved=2ahUKEwiZ9bmZ3J74AhXR_rsIHeArCjwQ_BJ6BAhAEAU",
			webLink: "https://www.parentucelli-arzela.edu.it/"
		},
		{
			name: "I.I.S.S. L. Einaudi - D. Chiodo",
			mapLink: "https://www.google.com/maps/place/Istituto+Einaudi+Chiodo/@44.1111799,9.8115276,15z/data=!4m2!3m1!1s0x0:0x2fbf8d194c7295d4?sa=X&ved=2ahUKEwjH44KB4J74AhUfh_0HHa8-BQQQ_BJ6BAg_EAU",
			webLink: "https://www.einaudichiodo.edu.it/"
		},
	],
	[EHobbies.meccanica]: [
		{
			name: "I.I.S.S. L. Einaudi - D. Chiodo",
			mapLink: "https://www.google.com/maps/place/Istituto+Einaudi+Chiodo/@44.1111799,9.8115276,15z/data=!4m2!3m1!1s0x0:0x2fbf8d194c7295d4?sa=X&ved=2ahUKEwjH44KB4J74AhUfh_0HHa8-BQQQ_BJ6BAg_EAU",
			webLink: "https://www.einaudichiodo.edu.it/"
		},
		{
			name: "CISITA - Formazione Superiore",
			mapLink: "https://www.google.com/maps/place/CISITA+-+Formazione+Superiore/@44.1115747,9.8536523,15z/data=!4m2!3m1!1s0x0:0x41d81da8a38ff342?sa=X&ved=2ahUKEwiejpSV4574AhX5Q_EDHX9PBp8Q_BJ6BAhZEAU",
			webLink: "https://www.cisita.it/"
		}
	],
	[EHobbies.teatro]: [
		{
			name: "Liceo Classico L. Costa",
			mapLink: "https://www.google.com/maps/place/Liceo+Classico+Statale+Lorenzo+Costa/@44.1068144,9.8261172,15z/data=!4m2!3m1!1s0x0:0xe6c10db028c6e7da?sa=X&ved=2ahUKEwjVlaTM4574AhUyRPEDHUQpAVoQ_BJ6BAhgEAU",
			webLink: "https://www.liceocosta.edu.it/"
		},
		{
			name: "I.S.S. Parentucelli Arzelà Liceo Classico",
			mapLink: "https://www.google.com/maps/place/I.I.S.+Parentucelli-Arzel%C3%A0/@44.1158646,9.9639358,15z/data=!4m2!3m1!1s0x0:0xc758751035450e1?sa=X&ved=2ahUKEwiZ9bmZ3J74AhXR_rsIHeArCjwQ_BJ6BAhAEAU",
			webLink: "https://www.parentucelli-arzela.edu.it/"
		},
	],
	[EHobbies.sport]: [
		{
			name: "Liceo Scientifico A. Pacinotti - indirizzo sportivo",
			mapLink: "https://www.google.it/maps/place/Liceo+Scientifico+Statale+Antonio+Pacinotti/@44.1080014,9.8067531,17z/data=!3m1!4b1!4m5!3m4!1s0x12d4fb66b7febad9:0x2513c742160765f2!8m2!3d44.1080014!4d9.8089418",
			webLink: "https://www.liceopacinotti.edu.it/"
		},
	],
	[EHobbies.nautica]: [
		{
			name: "I.I.S. G. Capellini - N. Sauro",
			mapLink: "https://www.google.com/maps/place/Istituto+Tecnico+Industriale+Giovanni+Capellini/@44.1115427,9.8364545,15z/data=!4m5!3m4!1s0x0:0xdc2cc6b6a1d43140!8m2!3d44.1117286!4d9.8362014",
			webLink: "https://www.capellinisauro.edu.it/"
		}
	],
	[EHobbies.psicologia]: [
		{
			name: "Liceo Mazzini",
			mapLink: "https://www.google.com/maps/place/Liceo+Mazzini/@44.1097945,9.8070449,15z/data=!4m2!3m1!1s0x0:0xa3a5a5f62501d40c?sa=X&ved=2ahUKEwiAiZm75Z74AhVLi_0HHZ-oDzYQ_BJ6BAhPEAU",
			webLink: "https://www.liceomazzini.edu.it/"
		}
	],
	[EHobbies.moda]: [
		{
			name: "I.I.S.S. L. Einaudi - D. Chiodo",
			mapLink: "https://www.google.com/maps/place/Istituto+Einaudi+Chiodo/@44.1111799,9.8115276,15z/data=!4m2!3m1!1s0x0:0x2fbf8d194c7295d4?sa=X&ved=2ahUKEwjH44KB4J74AhUfh_0HHa8-BQQQ_BJ6BAg_EAU",
			webLink: "https://www.einaudichiodo.edu.it/"
		},
	],
	[EHobbies.botanica]: [
		{
			name: "I.S.S. Parentucelli Arzelà Istituto Professionale Agrario",
			mapLink: "https://www.google.com/maps/place/I.I.S.+Parentucelli-Arzel%C3%A0/@44.1158646,9.9639358,15z/data=!4m2!3m1!1s0x0:0xc758751035450e1?sa=X&ved=2ahUKEwiZ9bmZ3J74AhXR_rsIHeArCjwQ_BJ6BAhAEAU",
			webLink: "https://www.parentucelli-arzela.edu.it/"
		},
		{
			name: "Liceo Scientifico A. Pacinotti",
			mapLink: "https://www.google.it/maps/place/Liceo+Scientifico+Statale+Antonio+Pacinotti/@44.1080014,9.8067531,17z/data=!3m1!4b1!4m5!3m4!1s0x12d4fb66b7febad9:0x2513c742160765f2!8m2!3d44.1080014!4d9.8089418",
			webLink: "https://www.liceopacinotti.edu.it/"
		},
		{
			name: "I.S.S. Parentucelli Arzelà Liceo Scientifico",
			mapLink: "https://www.google.com/maps/place/I.I.S.+Parentucelli-Arzel%C3%A0/@44.1158646,9.9639358,15z/data=!4m2!3m1!1s0x0:0xc758751035450e1?sa=X&ved=2ahUKEwiZ9bmZ3J74AhXR_rsIHeArCjwQ_BJ6BAhAEAU",
			webLink: "https://www.parentucelli-arzela.edu.it/"
		}
	],
	[EHobbies.musica]: [
		{
			name: "Cardarelli Liceo Musicale",
			mapLink: "https://www.google.com/maps/place/Istituto+Superiore+Statale+Vincenzo+Cardarelli/@44.1249056,9.8438311,15z/data=!4m2!3m1!1s0x0:0xd88e1c37c167ca86?sa=X&ved=2ahUKEwid9LX_4J74AhX2hP0HHcUiBpUQ_BJ6BAhREAU",
			webLink: "http://www.istitutocardarelli.edu.it/cardarelli/"
		}
	],
	[EHobbies.lingue]: [
		{
			name: "Liceo Mazzini",
			mapLink: "https://www.google.com/maps/place/Liceo+Mazzini/@44.1097945,9.8070449,15z/data=!4m2!3m1!1s0x0:0xa3a5a5f62501d40c?sa=X&ved=2ahUKEwiAiZm75Z74AhVLi_0HHZ-oDzYQ_BJ6BAhPEAU",
			webLink: "https://www.liceomazzini.edu.it/"
		}
	],
	[EHobbies.lettere]: [
		{
			name: "Liceo Classico L. Costa",
			mapLink: "https://www.google.com/maps/place/Liceo+Classico+Statale+Lorenzo+Costa/@44.1068144,9.8261172,15z/data=!4m2!3m1!1s0x0:0xe6c10db028c6e7da?sa=X&ved=2ahUKEwjVlaTM4574AhUyRPEDHUQpAVoQ_BJ6BAhgEAU",
			webLink: "https://www.liceocosta.edu.it/"
		},
		{
			name: "I.S.S. Parentucelli Arzelà Liceo Classico",
			mapLink: "https://www.google.com/maps/place/I.I.S.+Parentucelli-Arzel%C3%A0/@44.1158646,9.9639358,15z/data=!4m2!3m1!1s0x0:0xc758751035450e1?sa=X&ved=2ahUKEwiZ9bmZ3J74AhXR_rsIHeArCjwQ_BJ6BAhAEAU",
			webLink: "https://www.parentucelli-arzela.edu.it/"
		},
	],
	[EHobbies.scultura]: [
		{
			name: "Cardarelli Liceo Artistico",
			mapLink: "https://www.google.com/maps/place/Istituto+Superiore+Statale+Vincenzo+Cardarelli/@44.1249056,9.8438311,15z/data=!4m2!3m1!1s0x0:0xd88e1c37c167ca86?sa=X&ved=2ahUKEwid9LX_4J74AhX2hP0HHcUiBpUQ_BJ6BAhREAU",
			webLink: "http://www.istitutocardarelli.edu.it/cardarelli/"
		},
	],
	[EHobbies.turismo]: [
		{
			name: "Istituto Tecnico Commerciale e Tecnologico A. Fossati - M. Da Passano",
			mapLink: "https://www.google.com/maps/place/Istituto+Tecnico+Commerciale+e+Tecnologico+A.Fossati-M.Da+Passano/@44.116592,9.8488462,15z/data=!4m5!3m4!1s0x0:0xd2d345e515173bf8!8m2!3d44.116592!4d9.8488462",
			webLink: "https://www.fossatidapassano.edu.it/"
		},
		{
			name: "I.I.S.S. L. Einaudi - D. Chiodo",
			mapLink: "https://www.google.com/maps/place/Istituto+Einaudi+Chiodo/@44.1111799,9.8115276,15z/data=!4m2!3m1!1s0x0:0x2fbf8d194c7295d4?sa=X&ved=2ahUKEwjH44KB4J74AhUfh_0HHa8-BQQQ_BJ6BAg_EAU",
			webLink: "https://www.einaudichiodo.edu.it/"
		},
		{
			name: "I.S.S. Parentucelli Arzelà Istituto Tecnico",
			mapLink: "https://www.google.com/maps/place/I.I.S.+Parentucelli-Arzel%C3%A0/@44.1158646,9.9639358,15z/data=!4m2!3m1!1s0x0:0xc758751035450e1?sa=X&ved=2ahUKEwiZ9bmZ3J74AhXR_rsIHeArCjwQ_BJ6BAhAEAU",
			webLink: "https://www.parentucelli-arzela.edu.it/"
		},
	],
	[EHobbies.cucina]: [
		{
			name: "I.P.S.S.E.O.A. G. Casini",
			mapLink: "https://www.google.com/maps/place/Istituto+Professionale+di+Stato+dei+Servizi+per+l'Enogastronomia+e+l'Ospitalit%C3%A0+Alberghiera+Giuseppe+Casini/@44.1247496,9.8429869,15z/data=!4m5!3m4!1s0x0:0x16d8485beeb14140!8m2!3d44.1247496!4d9.8429869",
			webLink: "https://www.alberghierolaspezia.edu.it/"
		},
		{
			name: "CIOFS FP",
			mapLink: "https://www.google.com/maps/place/Centro+Italiano+Opere+Femminili+Salesiane+Formazione+Prof./@44.1012263,9.8219127,15z/data=!4m2!3m1!1s0x0:0x657b8ae1ca54689c?sa=X&ved=2ahUKEwjlzauL6Z74AhUCRuUKHeakCN4Q_BJ6BAgwEAU",
			webLink: "https://www.ciofsliguria.org/"
		}
	],
	[EHobbies.estetica]: [
		{
			name: "FORMIMPRESA",
			mapLink: "https://www.google.com/maps/place/Formimpresa+Liguria/@44.1180931,9.8447398,15z/data=!4m2!3m1!1s0x0:0xac34ee6c3d85a346?sa=X&ved=2ahUKEwjBwtCi6p74AhVGg_0HHU9DBB8Q_BJ6BAg7EAU",
			webLink: "https://www.formimpresaliguria.it/"
		},
		{
			name: "CIOFS FP",
			mapLink: "https://www.google.com/maps/place/Centro+Italiano+Opere+Femminili+Salesiane+Formazione+Prof./@44.1012263,9.8219127,15z/data=!4m2!3m1!1s0x0:0x657b8ae1ca54689c?sa=X&ved=2ahUKEwjlzauL6Z74AhUCRuUKHeakCN4Q_BJ6BAgwEAU",
			webLink: "https://www.ciofsliguria.org/"
		}
	],
	[EHobbies.storia]: [
		{
			name: "Liceo Classico L. Costa",
			mapLink: "https://www.google.com/maps/place/Liceo+Classico+Statale+Lorenzo+Costa/@44.1068144,9.8261172,15z/data=!4m2!3m1!1s0x0:0xe6c10db028c6e7da?sa=X&ved=2ahUKEwjVlaTM4574AhUyRPEDHUQpAVoQ_BJ6BAhgEAU",
			webLink: "https://www.liceocosta.edu.it/"
		},
		{
			name: "I.S.S. Parentucelli Arzelà Liceo Classico",
			mapLink: "https://www.google.com/maps/place/I.I.S.+Parentucelli-Arzel%C3%A0/@44.1158646,9.9639358,15z/data=!4m2!3m1!1s0x0:0xc758751035450e1?sa=X&ved=2ahUKEwiZ9bmZ3J74AhXR_rsIHeArCjwQ_BJ6BAhAEAU",
			webLink: "https://www.parentucelli-arzela.edu.it/"
		},
		{
			name: "Cardarelli Liceo Artistico",
			mapLink: "https://www.google.com/maps/place/Istituto+Superiore+Statale+Vincenzo+Cardarelli/@44.1249056,9.8438311,15z/data=!4m2!3m1!1s0x0:0xd88e1c37c167ca86?sa=X&ved=2ahUKEwid9LX_4J74AhX2hP0HHcUiBpUQ_BJ6BAhREAU",
			webLink: "http://www.istitutocardarelli.edu.it/cardarelli/"
		},
	],
	[EHobbies.animali]: [
		{
			name: "I.S.S. Parentucelli Arzelà Istituto Professionale Agrario",
			mapLink: "https://www.google.com/maps/place/I.I.S.+Parentucelli-Arzel%C3%A0/@44.1158646,9.9639358,15z/data=!4m2!3m1!1s0x0:0xc758751035450e1?sa=X&ved=2ahUKEwiZ9bmZ3J74AhXR_rsIHeArCjwQ_BJ6BAhAEAU",
			webLink: "https://www.parentucelli-arzela.edu.it/"
		},
	],
	[EHobbies.strumenti]: [
		{
			name: "Cardarelli Liceo Musicale",
			mapLink: "https://www.google.com/maps/place/Istituto+Superiore+Statale+Vincenzo+Cardarelli/@44.1249056,9.8438311,15z/data=!4m2!3m1!1s0x0:0xd88e1c37c167ca86?sa=X&ved=2ahUKEwid9LX_4J74AhX2hP0HHcUiBpUQ_BJ6BAhREAU",
			webLink: "http://www.istitutocardarelli.edu.it/cardarelli/"
		}
	],
	[EHobbies.chimica]: [
		{
			name: "I.I.S. G. Capellini - N. Sauro",
			mapLink: "https://www.google.com/maps/place/Istituto+Tecnico+Industriale+Giovanni+Capellini/@44.1115427,9.8364545,15z/data=!4m5!3m4!1s0x0:0xdc2cc6b6a1d43140!8m2!3d44.1117286!4d9.8362014",
			webLink: "https://www.capellinisauro.edu.it/"
		}
	],
	[EHobbies.pittura]: [
		{
			name: "Cardarelli Liceo Artistico",
			mapLink: "https://www.google.com/maps/place/Istituto+Superiore+Statale+Vincenzo+Cardarelli/@44.1249056,9.8438311,15z/data=!4m2!3m1!1s0x0:0xd88e1c37c167ca86?sa=X&ved=2ahUKEwid9LX_4J74AhX2hP0HHcUiBpUQ_BJ6BAhREAU",
			webLink: "http://www.istitutocardarelli.edu.it/cardarelli/"
		}
	],
	[EHobbies.agraria]: [
		{
			name: "I.S.S. Parentucelli Arzelà Istituto Professionale Agrario",
			mapLink: "https://www.google.com/maps/place/I.I.S.+Parentucelli-Arzel%C3%A0/@44.1158646,9.9639358,15z/data=!4m2!3m1!1s0x0:0xc758751035450e1?sa=X&ved=2ahUKEwiZ9bmZ3J74AhXR_rsIHeArCjwQ_BJ6BAhAEAU",
			webLink: "https://www.parentucelli-arzela.edu.it/"
		},
	],
	[EHobbies.cinema]: [
		{
			name: "Cardarelli Liceo Artistico - indirizzo multimediale",
			mapLink: "https://www.google.com/maps/place/Istituto+Superiore+Statale+Vincenzo+Cardarelli/@44.1249056,9.8438311,15z/data=!4m2!3m1!1s0x0:0xd88e1c37c167ca86?sa=X&ved=2ahUKEwid9LX_4J74AhX2hP0HHcUiBpUQ_BJ6BAhREAU",
			webLink: "http://www.istitutocardarelli.edu.it/cardarelli/"
		},
		{
			name: "I.I.S.S. L. Einaudi - D. Chiodo - professionale grafico multimediale",
			mapLink: "https://www.google.com/maps/place/Istituto+Einaudi+Chiodo/@44.1111799,9.8115276,15z/data=!4m2!3m1!1s0x0:0x2fbf8d194c7295d4?sa=X&ved=2ahUKEwjH44KB4J74AhUfh_0HHa8-BQQQ_BJ6BAg_EAU",
			webLink: "https://www.einaudichiodo.edu.it/"
		},
	],
	[EHobbies.tecnologia]: [
		{
			name: "CAT Cardarelli",
			mapLink: "https://www.google.com/maps/place/Istituto+di+Istruzione+Superiore+Statale+Cardarelli/@44.113514,9.8470103,15z/data=!4m5!3m4!1s0x0:0xa2127318071dff52!8m2!3d44.113523!4d9.84701",
			webLink: "http://www.istitutocardarelli.edu.it/cardarelli/"
		},
		{
			name: "I.I.S. G. Capellini - N. Sauro",
			mapLink: "https://www.google.com/maps/place/Istituto+Tecnico+Industriale+Giovanni+Capellini/@44.1115427,9.8364545,15z/data=!4m5!3m4!1s0x0:0xdc2cc6b6a1d43140!8m2!3d44.1117286!4d9.8362014",
			webLink: "https://www.capellinisauro.edu.it/"
		},
		{
			name: "Istituto Tecnico Commerciale e Tecnologico A. Fossati - M. Da Passano",
			mapLink: "https://www.google.com/maps/place/Istituto+Tecnico+Commerciale+e+Tecnologico+A.Fossati-M.Da+Passano/@44.116592,9.8488462,15z/data=!4m5!3m4!1s0x0:0xd2d345e515173bf8!8m2!3d44.116592!4d9.8488462",
			webLink: "https://www.fossatidapassano.edu.it/"
		},
		{
			name: "CISITA - Formazione Superiore",
			mapLink: "https://www.google.com/maps/place/CISITA+-+Formazione+Superiore/@44.1115747,9.8536523,15z/data=!4m2!3m1!1s0x0:0x41d81da8a38ff342?sa=X&ved=2ahUKEwiejpSV4574AhX5Q_EDHX9PBp8Q_BJ6BAhZEAU",
			webLink: "https://www.cisita.it/"
		},
		{
			name: "FORMIMPRESA",
			mapLink: "https://www.google.com/maps/place/Formimpresa+Liguria/@44.1180931,9.8447398,15z/data=!4m2!3m1!1s0x0:0xac34ee6c3d85a346?sa=X&ved=2ahUKEwjBwtCi6p74AhVGg_0HHU9DBB8Q_BJ6BAg7EAU",
			webLink: "https://www.formimpresaliguria.it/"
		},
	],
	[EHobbies.geometria]: [
		{
			name: "CAT Cardarelli",
			mapLink: "https://www.google.com/maps/place/Istituto+di+Istruzione+Superiore+Statale+Cardarelli/@44.113514,9.8470103,15z/data=!4m5!3m4!1s0x0:0xa2127318071dff52!8m2!3d44.113523!4d9.84701",
			webLink: "http://www.istitutocardarelli.edu.it/cardarelli/"
		},
		{
			name: "I.I.S. G. Capellini - N. Sauro",
			mapLink: "https://www.google.com/maps/place/Istituto+Tecnico+Industriale+Giovanni+Capellini/@44.1115427,9.8364545,15z/data=!4m5!3m4!1s0x0:0xdc2cc6b6a1d43140!8m2!3d44.1117286!4d9.8362014",
			webLink: "https://www.capellinisauro.edu.it/"
		},
		{
			name: "Cardarelli Liceo Artistico - indirizzo multimediale",
			mapLink: "https://www.google.com/maps/place/Istituto+Superiore+Statale+Vincenzo+Cardarelli/@44.1249056,9.8438311,15z/data=!4m2!3m1!1s0x0:0xd88e1c37c167ca86?sa=X&ved=2ahUKEwid9LX_4J74AhX2hP0HHcUiBpUQ_BJ6BAhREAU",
			webLink: "http://www.istitutocardarelli.edu.it/cardarelli/"
		},
		{
			name: "Liceo Scientifico A. Pacinotti",
			mapLink: "https://www.google.it/maps/place/Liceo+Scientifico+Statale+Antonio+Pacinotti/@44.1080014,9.8067531,17z/data=!3m1!4b1!4m5!3m4!1s0x12d4fb66b7febad9:0x2513c742160765f2!8m2!3d44.1080014!4d9.8089418",
			webLink: "https://www.liceopacinotti.edu.it/"
		},
		{
			name: "I.S.S. Parentucelli Arzelà Liceo Scientifico",
			mapLink: "https://www.google.com/maps/place/I.I.S.+Parentucelli-Arzel%C3%A0/@44.1158646,9.9639358,15z/data=!4m2!3m1!1s0x0:0xc758751035450e1?sa=X&ved=2ahUKEwiZ9bmZ3J74AhXR_rsIHeArCjwQ_BJ6BAhAEAU",
			webLink: "https://www.parentucelli-arzela.edu.it/"
		}
	]
};