import React from 'react';
import { StyleProp, Text, TextStyle, TouchableOpacity, View } from 'react-native';
import BottomLogobar from '../../ui/BottomLogobar';
import LogoIcon from '../../ui/icons/LogoIcon';

interface MainScreenProps {
	onNext: () => void
}

function MainScreen({ onNext }: MainScreenProps) {
	return (
		<TouchableOpacity style={{ flex: 1 }} onPress={onNext}>
			<View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
				<LogoIcon size="extraBig" />
				<Text style={titleStyle}> app(L)y</Text>
			</View>
			<BottomLogobar />
		</TouchableOpacity>
	)
}

const titleStyle: StyleProp<TextStyle> = {
	fontFamily: "Gontserrat",
	fontSize: 24
}

export default MainScreen;