import * as Linking from 'expo-linking';
import React from 'react';
import { Platform, StyleProp, View, ViewStyle } from 'react-native';
import { ISchool } from '../../common/interfaces';
import i18 from '../../lib/i18';
import LinkButton from '../button/LinkButton';
import MapsIcon from '../icons/MapsIcon';
import WebIcon from '../icons/WebIcon';
import Title from '../text/Title';

interface SchoolProps {
	school: ISchool
}

function School({ school }: SchoolProps) {
	console.log('school', school)
	const openWeb = () => {
		if (school.webLink) {
			if (Platform.OS == 'web') {
				window.open(school.webLink, '_blank');
			} else {
				Linking.openURL(school.webLink);
			}
		}
	}
	const openMap = () => {
		if (school.mapLink) {
			if (Platform.OS == 'web') {
				window.open(school.mapLink, '_blank');
			} else {
				Linking.openURL(school.mapLink);
			}
		}
	}
	return (
		<View style={containerStyle}>
			<Title>{school.name} </Title>
			<View style={Platform.OS === "web" ? buttonsContainerWebStyle : buttonsContainerStyle} >
				<View style={Platform.OS === "web" ? buttonWebStyle : buttonStyle}>
					<LinkButton Icon={WebIcon} text={i18.t("link to the website")} onPress={openWeb} />
				</View>
				<View style={Platform.OS === "web" ? buttonWebStyle : buttonStyle}>
					<LinkButton Icon={MapsIcon} text={i18.t("link to google maps")} onPress={openMap} />
				</View>
			</View>
		</View>
	)
}



const containerStyle: StyleProp<ViewStyle> = {
	padding: 20,
}

const buttonsContainerStyle: StyleProp<ViewStyle> = {

}

const buttonsContainerWebStyle: StyleProp<ViewStyle> = {
	flexDirection: "row",
	justifyContent: "center",
	padding: 40
}

const buttonStyle: StyleProp<ViewStyle> = {
	padding: 40,
	alignItems: "center"
}

const buttonWebStyle: StyleProp<ViewStyle> = {
	padding: 40,
	flexDirection: "row",
	alignItems: "center"
}

export default School;