import React from "react";
import { Image, View } from "react-native";
import { IconProps } from "../../props";
import { iconBig, iconRegular, iconSelected, iconSmall } from "../../styles";

function PsicologiaIcon({ selected, size }: IconProps) {
	return (
		<View>
			<Image
				source={require('./psicologia.png')}
				fadeDuration={0}
				resizeMode="contain"
				style={size === "small" ? iconSmall : size === "big" ? iconBig : iconRegular}
			/>

			{selected &&
				<Image
					source={require('../../extra/Selected.png')}
					fadeDuration={0}
					resizeMode="contain"
					style={iconSelected}
				/>
			}
		</View>
	)
}

export default PsicologiaIcon;