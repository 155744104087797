import React from 'react';
import { Platform, StyleProp, View, ViewStyle } from 'react-native';
import MinisteroInternoLogo from '../logos/MinisteroInternoLogo';
import MinisteroLavoroLogo from '../logos/MinisteroLavoroLogo';
import RegioneLiguriaLogo from '../logos/RegioneLiguriaLogo';
import UnioneEuropeaLogo from '../logos/UnioneEuropeaLogo';


function Logobar() {
	return (
		<View style={logobarStyle}>
			<View style={Platform.OS === "web" ? containerWebStyle : containerStyle}>
				<View style={{ flex: 1 }}>
					<UnioneEuropeaLogo />
				</View>
				<View style={{ flex: 1 }}>
					<RegioneLiguriaLogo />
				</View >
				<View style={{ flex: 1 }}>
					<MinisteroLavoroLogo />
				</View>
				<View style={{ flex: 1 }}>
					<MinisteroInternoLogo />
				</View>
			</View>
		</View>
	)
}

const logobarStyle: StyleProp<ViewStyle> = {
	justifyContent: "center",
	flexDirection: "row"
}
const containerStyle: StyleProp<ViewStyle> = {
	flexDirection: "row",
	width: "100%",
	paddingTop: 10,
	paddingHorizontal: 20
}
const containerWebStyle: StyleProp<ViewStyle> = {
	flexDirection: "row",
	width: "100%",
	maxWidth: 1360,
	paddingTop: 10,
	paddingHorizontal: 20
}

export default Logobar;