/** Chinese - zh */
export default {
	"choose your language": "选择你的语言",
	"choose three of your hobbies": "选择你的三个兴趣爱好",
	"choose one of the recommended options": "选择推荐的选项之一",
	"link to the website": "链接到网站",
	"link to google maps": "链接到谷歌地图",
	"medicine": "医学",
	"photography": "摄影",
	"singing": "歌",
	"mathematics": "数学",
	"economy": "经济学",
	"mechanics": "机械学",
	"theater": "剧院",
	"sport": "运动",
	"boating": "航海学",
	"psychology": "心理学",
	"fashion": "时尚",
	"botany": "植物学",
	"music": "音乐",
	"languages": "语言",
	"letters": "字母",
	"sculpture": "雕塑",
	"tourism": "旅游",
	"cooking": "厨房",
	"beauty": "美学",
	"history": "历史",
	"animals": "动物",
	"instruments": "工具",
	"chemistry": "化学",
	"painting": "画",
	"agricultur": "农业学",
	"cinema": "电影院",
	"technology": "技术",
	"geometry": "几何学"
};