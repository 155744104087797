import React, { useEffect, useState } from 'react';
import { Platform, ScrollView, StyleProp, View, ViewStyle } from 'react-native';
import { EHobbies } from '../../common/enums';
import { ISchool } from '../../common/interfaces';
import { hobbiesToSchool } from '../../lib/schools';
import IconButton from '../../ui/button/IconButton';
import PrevIcon from '../../ui/icons/PrevIcon';
import School from '../../ui/School';
import Title from '../../ui/text/Title';

interface SchoolProps {
	schools: ISchool[];
	onPrev: () => void;
}

function SchoolScreen({ schools, onPrev }: SchoolProps) {
	return (
		<View style={containerStyle}>
			<Title></Title>
			<ScrollView
				style={Platform.OS === "web" ? schoolScroolWebStyle : schoolScroolStyle}
				contentContainerStyle={schoolListContainerStyle}
			>
				{schools?.map((school, index) => (
					<View key={index} style={schoolContainerStyle}>
						{index !== 0 && <View style={divisorStyle}></View>}
						<School school={school} />
					</View>
				))}
			</ScrollView>
			<View style={Platform.OS === "web" ? nextWebStyle : nextStyle}>
				<View >
					<IconButton Icon={PrevIcon} onPress={onPrev}></IconButton>
				</View>
				<View />
			</View>
		</View>
	);
}


const containerStyle: StyleProp<ViewStyle> = {
	flex: 1,
	paddingTop: 0,
	alignItems: "center",
}

const schoolContainerStyle: StyleProp<ViewStyle> = {
	alignItems: 'center'
}

const divisorStyle: StyleProp<ViewStyle> = {
	width: 200,
	height: 1,
	backgroundColor: "#000"
}

const schoolScroolStyle: StyleProp<ViewStyle> = {

}
const schoolScroolWebStyle: StyleProp<ViewStyle> = {
	width: "100%",
	maxWidth: 1360
}

const schoolListContainerStyle: StyleProp<ViewStyle> = {
	justifyContent: "center"
}

const nextStyle: StyleProp<ViewStyle> = {
	width: "100%",
	height: 80,
	flexDirection: "row",
	justifyContent: "space-between",
	paddingHorizontal: 20,
	paddingBottom: 20
}

const nextWebStyle: StyleProp<ViewStyle> = {
	width: "100%",
	maxWidth: 1360,
	height: 80,
	flexDirection: "row",
	justifyContent: "space-between",
	paddingHorizontal: 20,
	paddingBottom: 20
}

export default SchoolScreen;