import React from "react";
import { TouchableOpacity, TouchableOpacityProps } from "react-native";
import { IconProps } from '../../icons/props';
import Underlined from "../../text/Underlined";

interface LinkButtonProps extends TouchableOpacityProps {
	Icon: (props: IconProps) => JSX.Element;
	text: string;
}

function LinkButton({ onPress, Icon, text }: LinkButtonProps) {
	return (
		<TouchableOpacity onPress={onPress} style={{
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center"
		}}>
			<Icon size="big" />
			<Underlined style={{ marginLeft: 8 }}>{text}</Underlined>
		</TouchableOpacity >
	)
}

export default LinkButton;