export enum EPages {
	"main" = "main",
	"languages" = "languages",
	"hobbies" = "hobbies",
	"choice" = "choice",
	"school" = "school"
}

export enum ELanguages {
	"ar" = "ar",
	"bn" = "bn",
	"en" = "en",
	"es" = "es",
	"fr" = "fr",
	"it" = "it",
	"sq" = "sq",
	"zh" = "zh"
}

export enum EHobbies {
	"agraria" = "agraria",
	"animali" = "animali",
	"botanica" = "botanica",
	"canto" = "canto",
	"chimica" = "chimica",
	"cinema" = "cinema",
	"cucina" = "cucina",
	"economia" = "economia",
	"estetica" = "estetica",
	"fotografia" = "fotografia",
	"geometria" = "geometria",
	"lettere" = "lettere",
	"lingue" = "lingue",
	"matematica" = "matematica",
	"meccanica" = "meccanica",
	"medicina" = "medicina",
	"moda" = "moda",
	"musica" = "musica",
	"nautica" = "nautica",
	"pittura" = "pittura",
	"psicologia" = "psicologia",
	"scultura" = "scultura",
	"sport" = "sport",
	"storia" = "storia",
	"strumenti" = "strumenti",
	"teatro" = "teatro",
	"tecnologia" = "tecnologia",
	"turismo" = "turismo"
}