/** Albanian - sq */
export default {
	"choose your language": "zgjidh gjuhën tënde",
	"choose three of your hobbies": "zgjidh tre nga hobby-t e tua",
	"choose one of the recommended options": "zgjidh një nga opsionet ",
	"link to the website": "link në website-in",
	"link to google maps": "link në Google Maps ",
	"medicine": "mjeksi",
	"photography": "fotografi",
	"singing": "kanto",
	"mathematics": "matematik",
	"economy": "ekonomi",
	"mechanics": "mekanik",
	"theater": "teatro",
	"sport": "sport",
	"boating": "marinë",
	"psychology": "psikollogji",
	"fashion": "moda",
	"botany": "botanik",
	"music": "muzik",
	"languages": "gju të huaja",
	"letters": "letërsi",
	"sculpture": "skulptur",
	"tourism": "turizëm",
	"cooking": "gatim",
	"beauty": "estetik",
	"history": "histori",
	"animals": "kafsh",
	"instruments": "strumente",
	"chemistry": "kimi",
	"painting": "piktur",
	"agricultur": "agronomi",
	"cinema": "kinema",
	"technology": "teknollogji",
	"geometry": "gjeometri"
};